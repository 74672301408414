<template>
  <div class="KpiData_wrapper">
    <el-tabs type="card" v-model="currView">
      <el-tab-pane label="阶段任务" name="StageTask"></el-tab-pane>
      <el-tab-pane label="奖励金" name="EmployeeBonus"></el-tab-pane>
      <el-tab-pane label="积分" name="Integral"></el-tab-pane>
      <el-tab-pane label="品牌惩罚金" name="BannerPunish"></el-tab-pane>
      <el-tab-pane label="排行榜" name="RankList"></el-tab-pane>
    </el-tabs>
    <keep-alive>
      <component :is="currView" :activityID="activityID"></component>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "KpiData", // Kpi 数据
  components: {
    StageTask: () => import("./KpidataChildren/StageTask"),
    EmployeeBonus: () => import("./KpidataChildren/EmployeeBonus"),
    Integral: () => import("./KpidataChildren/Integral"),
    BannerPunish: () => import("./KpidataChildren/BannerPunish"),
    RankList: () => import("./KpidataChildren/RankList"),
  },
  props: {
    activityID: String
  },
  data() {
    return {
      currView: "StageTask"
    };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.KpiData_wrapper {
}
</style>
